import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./images/squid.png" alt="Squid Vector Illustration." />
        <h1> Squiiids Id Finder coming soon...</h1>
      </header>
    </div>
  );
}

export default App;
